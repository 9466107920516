import { makeStyles } from "@material-ui/core/styles"

const drawerWidth = 240

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 150,
  },
  fab: {
    marginTop: theme.spacing(1),
  },
  backIcon: {
    color: "white",
  },
  player: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "80px",
  },
  sliderContainer: {
    width: "492px",
    [theme.breakpoints.down("sm")]: {
      width: "303px",
    },
  },
  slickDots: {
    "& li": {
      [theme.breakpoints.down("sm")]: {
        margin: "0 1px",
      },
    },
  },
  board: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "602px",
    [theme.breakpoints.down("sm")]: {
      height: "413px",
    },
  },
  boardTitle: {
    fontSize: "1.6rem",
    fontWeight: "bold",
    height: "30px",
    marginBottom: "20px",
    color: "rgba(0, 0, 0, 0.70)",
  },
  boardMain: {
    flexGrow: 1,
    border: "solid 3px rgba(0, 0, 0, 0.54)",
    display: "flex",
    flexWrap: "wrap",
  },
  boardController: {
    minHeight: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cell: {
    position: "relative",
    width: "54px",
    height: "54px",
    [theme.breakpoints.down("sm")]: {
      width: "33px",
      height: "33px",
    },
    border: "solid 1px rgba(0, 0, 0, 0.54)",
  },
  cellBorderRight: {
    borderRight: "solid 3px rgba(0, 0, 0, 0.54)",
  },
  cellBorderBottom: {
    borderBottom: "solid 3px rgba(0, 0, 0, 0.54)",
  },
  cellFixed: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
    cursor: "not-allowed",
  },
  cellFocused: {
    outline: "solid 7px rgba(0, 0, 0, 0.50)",
    [theme.breakpoints.down("sm")]: {
      outline: "solid 5px rgba(0, 0, 0, 0.50)",
    },
  },
  cellDigitSingle: {
    textAlign: "center",
    fontSize: "40px",
    lineHeight: "54px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "33px",
    },
  },
  cellDigit: {
    position: "absolute",
    fontSize: "16px",
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "10px",
      color: "#000",
    },
  },
  cellDigit1: {
    top: "2px",
    left: "3px",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: "2px",
    },
  },
  cellDigit2: {
    top: "2px",
    left: "19px",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: "12px",
    },
  },
  cellDigit3: {
    top: "2px",
    left: "36px",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: "22px",
    },
  },
  cellDigit4: {
    top: "18px",
    left: "3px",
    [theme.breakpoints.down("sm")]: {
      top: "10.5px",
      left: "2px",
    },
  },
  cellDigit5: {
    top: "18px",
    left: "19px",
    [theme.breakpoints.down("sm")]: {
      top: "10.5px",
      left: "12px",
    },
  },
  cellDigit6: {
    top: "18px",
    left: "36px",
    [theme.breakpoints.down("sm")]: {
      top: "10.5px",
      left: "22px",
    },
  },
  cellDigit7: {
    top: "35px",
    left: "3px",
    [theme.breakpoints.down("sm")]: {
      top: "21px",
      left: "2px",
    },
  },
  cellDigit8: {
    top: "35px",
    left: "19px",
    [theme.breakpoints.down("sm")]: {
      top: "21px",
      left: "12px",
    },
  },
  cellDigit9: {
    top: "35px",
    left: "36px",
    [theme.breakpoints.down("sm")]: {
      top: "21px",
      left: "22px",
    },
  },
}))
