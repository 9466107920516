import _ from "lodash"
import { API_GATEWAY_URL, API_KEY } from "../config/config"

export const fetchApi = async (
  token,
  path,
  method = "GET",
  data = {},
  searchParams = {}
) => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    "x-api-key": API_KEY,
  }
  if (!_.isEmpty(token)) {
    headers["Authorization"] = `Bearer ${token}`
  }
  const options = {
    headers,
    method,
  }
  if (method === "POST" || method === "PUT") {
    options.body = JSON.stringify(data)
  }

  try {
    const url = new URL(API_GATEWAY_URL + path)
    if (method === "GET" && !_.isEmpty(searchParams)) {
      const params = new URLSearchParams()
      _.map(searchParams, (value, key) => {
        params.append(key, value)
      })
      url.search = params.toString()
    }
    const response = await fetch(url.toString(), options)
    return await response.json()
  } catch (error) {
    console.error(error)
  }
}
